import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import lodash from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllRoles } from "src/clients/RolesAndPermissionsService";
import { selectUser } from "src/redux/slices/userSlice";
import { Role } from "src/types/RolesAndPermissions";
import { UserAppCredentials, UserData } from "src/types/UserData";
import { useClientDataContext } from "../context/Context";
import WarningPopup from "../modal/WarningPopup";
import createUserStyles from "./CreateUser.module.css";

interface createUserProps {
  index: number;
  mode: string;
  handleOnCreateUserCancel: () => void;
  handleOnSuccessBack: () => void;
}
const CreateUser = ({
  index,
  mode,
  handleOnCreateUserCancel,
  handleOnSuccessBack,
}: createUserProps) => {
  const { clientData, setClientData } = useClientDataContext();
  const user = useSelector(selectUser);
  const initialUserData =
    index !== -1
      ? clientData.userDataList[index]
      : {
          firstName: "",
          lastName: "",
          userId: "",
          password: "",
          email: "",
          role:
            user.role === "SYSTEM_ADMIN"
              ? { roleId: 2, roleName: "ADMIN" }
              : user.role === "ADMIN"
                ? { roleId: 3, roleName: "USER" }
                : { roleId: 1, roleName: "" },
          userAppCredentials: [],
          status: "INACTIVE",
          onBoarded: false,
          defaultProduct: "",
        };
  const [currentUserData, setCurrentUserData] = useState<UserData>({
    ...initialUserData,
    role: { ...initialUserData.role },
    userAppCredentials: initialUserData.userAppCredentials.map(
      (userAppCredential) => ({ ...userAppCredential })
    ),
  });
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [productsError, setProductsError] = useState<boolean>(false);
  const [isDiscardPopUpOpen, setIsDiscardPopUpOpen] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const statusList = ["ACTIVE", "INACTIVE"];
  const isUserEdit = user.permissionsList.includes("EDIT USER");
  let productsList: UserAppCredentials[] = clientData.appCredentialsList.map(
    (appCredential) => ({
      prdUsername: appCredential.prdUsername,
      prdPassword: appCredential.prdPassword,
      onBoarded: false,
      products: appCredential.products,
    })
  );

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (index !== -1) {
      setCurrentUserData((prev) => ({
        ...prev,
        ...initialUserData,
        role: { ...initialUserData.role },
        userAppCredentials: initialUserData.userAppCredentials.map(
          (userAppCredential) => ({ ...userAppCredential })
        ),
      }));
    }
  }, [clientData.userDataList]);

  useEffect(() => {
    if (firstNameError === true) {
      setFirstNameError(false);
    }
    if (lastNameError === true) {
      setLastNameError(false);
    }
    if (emailError === true) {
      setEmailError(false);
    }
    if (roleError === true) {
      setRoleError(false);
    }
    if (productsError === true) {
      setProductsError(false);
    }
  }, [
    currentUserData.firstName,
    currentUserData.lastName,
    currentUserData.email,
    currentUserData.role,
    currentUserData.userAppCredentials,
  ]);

  const fetchRoles = () => {
    getAllRoles(user.userId, user.password).then(async (res) => {
      if (res.ok) {
        const rolesData = await res.json();
        let filteredData;
        if (user.role === "SYSTEM_ADMIN") {
          filteredData = rolesData.filter(
            (roleData: Role) => roleData.roleName !== "SYSTEM_ADMIN"
          );
          setRoleList(filteredData);
        } else if (user.role === "ADMIN") {
          filteredData = rolesData.filter(
            (roleData: Role) =>
              roleData.roleName !== "SYSTEM_ADMIN" &&
              roleData.roleName !== "ADMIN"
          );
          setRoleList(filteredData);
        } else {
          setRoleList([]);
        }
      } else {
        toast.error("Failed to fetch Roles");
      }
    });
  };

  const isEmail = (email: string) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,6}$/.test(email);
  };

  const validateUserFields = () => {
    let userIdExp = "^[a-zA-Z ]{3,}$";
    if (
      !currentUserData.firstName.match(userIdExp) ||
      !currentUserData.lastName.match(userIdExp)
    ) {
      if (!currentUserData.firstName.match(userIdExp)) {
        setFirstNameError(true);
      }
      if (!currentUserData.lastName.match(userIdExp)) {
        setLastNameError(true);
      }
    } else if (!isEmail(currentUserData.email)) {
      setEmailError(true);
    } else if (
      currentUserData.role.roleId === 0 ||
      currentUserData.userAppCredentials.length === 0
    ) {
      if (currentUserData.role.roleId === 0) {
        setRoleError(true);
      }
      if (currentUserData.userAppCredentials.length === 0) {
        setProductsError(true);
      }
    } else {
      currentUserData.userId =
        currentUserData.userId !== ""
          ? currentUserData.userId
          : currentUserData.email.split("@")[0];
      currentUserData.defaultProduct === "" &&
        (currentUserData.defaultProduct =
          currentUserData.userAppCredentials[0].products.productName);
      saveUser(currentUserData);
      handleOnSuccessBack();
    }
  };

  const handleOnRoleChange = (role: Role) => {
    setCurrentUserData((prevState) => ({
      ...prevState,
      role:
        prevState.role.roleId === role.roleId
          ? { roleId: 0, roleName: "" }
          : role,
    }));
  };

  const handleOnDefaultProductChange = (defaultProduct: string) => {
    setCurrentUserData((prevState) => ({
      ...prevState,
      defaultProduct:
        prevState.defaultProduct === defaultProduct ? "" : defaultProduct,
    }));
  };

  const handleOnStatusChange = (status: string) => {
    setCurrentUserData((prevState) => ({
      ...prevState,
      status: status,
    }));
  };

  const handleOnProductsChange = (userAppCred: UserAppCredentials) => {
    setCurrentUserData((prevState) => {
      const updatedCurrentUserData = { ...prevState };
      const updatedUserAppCredentials = [
        ...updatedCurrentUserData.userAppCredentials,
      ];
      const isProductInList = updatedUserAppCredentials.some(
        (userAppCredential) => {
          return (
            userAppCredential.products?.productId ===
            userAppCred.products?.productId
          );
        }
      );

      if (isProductInList) {
        const index = updatedUserAppCredentials.findIndex(
          (userAppCredential) =>
            userAppCredential.products?.productId ===
            userAppCred.products?.productId
        );
        updatedUserAppCredentials.splice(index, 1);
      } else {
        updatedUserAppCredentials.push(userAppCred);
      }
      updatedCurrentUserData.userAppCredentials = updatedUserAppCredentials;

      if (updatedUserAppCredentials.length > 0) {
        updatedCurrentUserData.defaultProduct =
          updatedUserAppCredentials[0].products?.productName;
      } else {
        updatedCurrentUserData.defaultProduct = "";
      }
      return updatedCurrentUserData;
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleAppCredentialsInputChange = (
  //   event: ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { name, value } = event.target;
  //   const [, index, field] = name.split(".");
  //   setCurrentUserData((prevState) => {
  //     const userAppCredentialsList = [...prevState.userAppCredentials];
  //     const userAppCredential = {
  //       ...userAppCredentialsList[parseInt(index, 10)],
  //       [field]: value,
  //     };
  //     userAppCredentialsList[parseInt(index, 10)] = userAppCredential;
  //     return { ...prevState, userAppCredentials: userAppCredentialsList };
  //   });
  // };

  // const handleTogglePasswordVisibility = (index: number) => {
  //   setShowPassword((prevShowPassword) => !prevShowPassword);
  // };

  const saveUser = (newUserData: UserData) => {
    setClientData((prevState) => {
      const updatedClientData = { ...prevState };
      const updatedUserDataList = [...updatedClientData.userDataList];
      if (index !== -1) {
        const updatedUser = {
          ...updatedUserDataList[index],
          firstName: newUserData.firstName,
          lastName: newUserData.lastName,
          userId: newUserData.userId,
          email: newUserData.email,
          role: newUserData.role,
          userAppCredentials: [...newUserData.userAppCredentials],
          defaultProduct: newUserData.defaultProduct,
          status: newUserData.status,
        };
        updatedUserDataList[index] = updatedUser;
      } else {
        updatedUserDataList.push(newUserData);
      }
      updatedClientData.userDataList = updatedUserDataList;
      return updatedClientData;
    });
  };

  const handleOnSave = () => {
    validateUserFields();
  };

  const handleOnCancel = () => {
    if (!lodash.isEqual(initialUserData, currentUserData)) {
      setIsDiscardPopUpOpen(true);
    } else {
      handleOnCreateUserCancel();
    }
  };

  const handleOnPopUpSave = () => {
    setIsDiscardPopUpOpen(false);
    handleOnSave();
  };

  const handleOnPopUpDiscard = () => {
    setIsDiscardPopUpOpen(false);
    if (mode === "UserEdit") {
      setCurrentUserData(initialUserData);
    }
    handleOnCreateUserCancel();
  };

  const handleOnWarningPopUpClose = () => {
    setIsDiscardPopUpOpen(false);
  };

  return (
    <>
      <Box className={createUserStyles.rootContainer}>
        <Box>
          <Typography
            variant="h5"
            sx={{ padding: "2%" }}
          >
            {mode === "Edit" || mode === "UserEdit"
              ? "Edit User"
              : "Create User"}
          </Typography>
        </Box>
        <Box sx={{ padding: "0rem 2rem 1rem 2rem" }}>
          <Stack
            direction="column"
            spacing={2}
          >
            <Stack
              direction="row"
              spacing={10}
            >
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  First Name*
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="First Name"
                  fullWidth
                  required
                  name="firstName"
                  value={currentUserData.firstName}
                  onChange={handleInputChange}
                  helperText={firstNameError ? "Incorrect First Name" : ""}
                  InputProps={{
                    readOnly: !isUserEdit,
                  }}
                />
              </Box>
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  Last Name*
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="Last Name"
                  fullWidth
                  name="lastName"
                  value={currentUserData.lastName}
                  onChange={handleInputChange}
                  helperText={lastNameError ? "Incorrect Last Name" : ""}
                  InputProps={{
                    readOnly: !isUserEdit,
                  }}
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              spacing={10}
            >
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  Email*
                </Typography>
                <TextField
                  type="email"
                  size="small"
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  disabled={currentUserData.id !== undefined}
                  name="email"
                  value={currentUserData.email}
                  onChange={handleInputChange}
                  helperText={emailError ? "Invalid Email" : ""}
                />
              </Box>
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  userId
                </Typography>
                <TextField
                  type="text"
                  size="small"
                  placeholder="userId"
                  fullWidth
                  name="userId"
                  value={
                    currentUserData.userId
                      ? currentUserData.userId
                      : currentUserData.email.split("@")[0]
                  }
                  onChange={handleInputChange}
                  disabled={currentUserData.id !== undefined}
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              spacing={10}
            >
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  Role*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ alignSelf: "center" }}
                >
                  <Select
                    value={currentUserData.role.roleName}
                    size="small"
                    displayEmpty
                    disabled={mode === "UserEdit"}
                    readOnly={!isUserEdit}
                    renderValue={(value) =>
                      value !== "" ? (
                        value
                      ) : (
                        <span style={{ color: "#aaa" }}> Role</span>
                      )
                    }
                  >
                    {roleList.map((role) => (
                      <MenuItem
                        key={role.roleId}
                        value={role.roleName}
                        onClick={() => handleOnRoleChange(role)}
                      >
                        {role.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                  {roleError && (
                    <FormHelperText>Please Select Role</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  Products*
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ alignSelf: "center" }}
                >
                  <Select
                    displayEmpty
                    size="small"
                    multiple
                    disabled={mode === "UserEdit"}
                    readOnly={!isUserEdit}
                    value={currentUserData.userAppCredentials.map(
                      (userAppCredential) =>
                        userAppCredential.products?.productName
                    )}
                    renderValue={(selectedValues) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selectedValues.length !== 0 ? (
                          selectedValues.map((value: string) => (
                            <Chip
                              key={value}
                              label={value}
                            />
                          ))
                        ) : (
                          <span style={{ color: "#aaa" }}> Products</span>
                        )}
                      </Box>
                    )}
                  >
                    {productsList.map((userAppCredential) => (
                      <MenuItem
                        key={userAppCredential.products?.productId}
                        value={userAppCredential.products?.productName}
                        onClick={() =>
                          handleOnProductsChange(userAppCredential)
                        }
                      >
                        {userAppCredential.products?.productName}
                      </MenuItem>
                    ))}
                  </Select>
                  {productsError && (
                    <FormHelperText>
                      Please Select atleast one product
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Stack>

            <Stack
              direction="row"
              spacing={10}
            >
              <Box className={createUserStyles.stackBox}>
                <Typography
                  variant="body1"
                  className={createUserStyles.typographyBox}
                >
                  Default Product
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ alignSelf: "center" }}
                >
                  <Select
                    value={currentUserData.defaultProduct}
                    size="small"
                    displayEmpty
                    disabled={mode === "UserEdit"}
                    readOnly={!isUserEdit}
                    renderValue={(value) =>
                      value !== "" ? (
                        value
                      ) : (
                        <span style={{ color: "#aaa" }}> Default Product</span>
                      )
                    }
                  >
                    {currentUserData.userAppCredentials.length !== 0 ? (
                      currentUserData.userAppCredentials.map(
                        (userAppCredential) => (
                          <MenuItem
                            key={userAppCredential.products?.productId}
                            value={userAppCredential.products?.productName}
                            onClick={() =>
                              handleOnDefaultProductChange(
                                userAppCredential.products.productName
                              )
                            }
                          >
                            {userAppCredential.products?.productName}
                          </MenuItem>
                        )
                      )
                    ) : (
                      <MenuItem value={"Please Select Products"}>
                        {"Please Select Products"}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box className={createUserStyles.stackBox}>
                {mode !== "UserEdit" ? (
                  <>
                    <Typography
                      variant="body1"
                      className={createUserStyles.typographyBox}
                    >
                      Status
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{ alignSelf: "center" }}
                    >
                      <Select
                        value={currentUserData.status}
                        size="small"
                        displayEmpty
                        disabled={
                          !currentUserData.onBoarded ||
                          mode === "UserEdit" ||
                          productsList.every((product) => {
                            const correspondingAppCredential =
                              clientData.appCredentialsList.find(
                                (appCred) =>
                                  appCred.products.productId ===
                                  product.products.productId
                              );
                            return correspondingAppCredential
                              ? !correspondingAppCredential.isActive
                              : true;
                          })
                        }
                        readOnly={!isUserEdit}
                        renderValue={(value) =>
                          value !== "" ? (
                            value
                          ) : (
                            <span style={{ color: "#aaa" }}> Status</span>
                          )
                        }
                      >
                        {statusList.map((status, index) => (
                          <MenuItem
                            key={index}
                            value={status}
                            onClick={() => handleOnStatusChange(status)}
                          >
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Stack>

            {/* <Box sx={{ maxHeight: "20vh", overflowY: "scroll" }}>
              {currentUserData.userAppCredentials.map(
                (userAppCredential, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                      margin: "1rem 0rem",
                    }}
                    key={index}
                  >
                    <Accordion sx={{ width: "100%" }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          variant="body1"
                          className={createUserStyles.typographyBox}
                        >
                          {userAppCredential.products?.productName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack
                          direction={"column"}
                          spacing={2}
                        >
                          <Stack
                            direction="row"
                            spacing={10}
                          >
                            <Box className={createUserStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createUserStyles.typographyBox}
                              >
                                User Name
                              </Typography>
                              <TextField
                                type="text"
                                size="small"
                                placeholder="User Name"
                                fullWidth
                                name={`appCredentialsList.${index}.username`}
                                autoComplete="off"
                                value={userAppCredential.username}
                                onChange={handleAppCredentialsInputChange}
                              />
                            </Box>
                            <Box className={createUserStyles.stackBox}>
                              <Typography
                                variant="body1"
                                className={createUserStyles.typographyBox}
                              >
                                Password
                              </Typography>
                              <TextField
                                type={showPassword ? "text" : "password"}
                                size="small"
                                placeholder="Password"
                                fullWidth
                                name={`appCredentialsList.${index}.password`}
                                autoComplete="new-password"
                                value={userAppCredential.password}
                                onChange={handleAppCredentialsInputChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          handleTogglePasswordVisibility(index)
                                        }
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Stack>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                )
              )}
            </Box> */}
          </Stack>
        </Box>
      </Box>
      <Box className={createUserStyles.actionBox}>
        <Box className={createUserStyles.actionButtonsBox}>
          <Button
            variant="outlined"
            onClick={handleOnSave}
            disabled={lodash.isEqual(initialUserData, currentUserData)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {isDiscardPopUpOpen && (
        <WarningPopup
          isOpen={true}
          onClose={handleOnWarningPopUpClose}
          actionButtons={[
            {
              name: "Save",
              onButtonClick: handleOnPopUpSave,
            },
            {
              name: "Discard",
              onButtonClick: handleOnPopUpDiscard,
            },
          ]}
        >
          <Box className={createUserStyles.warningPopUpBox}>
            <WarningIcon />
            <Typography variant="body2">
              There are changes in the data do you want to save it.
            </Typography>
          </Box>
        </WarningPopup>
      )}
    </>
  );
};

export default CreateUser;
