import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { stepItem } from "src/utils/Common";
import popupstyles from "./Popup.module.css";

interface PopupProps {
  isOpen: boolean;
  menuList: string[];
  onClose: () => void;
  onDataSubmit: (data: any) => void;
  keywordName?: string;
  textName?: string;
}

const Popup = ({
  isOpen,
  menuList,
  onClose,
  onDataSubmit,
  keywordName,
  textName,
}: PopupProps) => {
  const [open, setOpen] = useState<boolean>(isOpen);
  const [isChecked, setIsChecked] = useState(false);
  const [keyword, setKeyword] = useState(
    keywordName !== undefined ? keywordName : ""
  );
  const [text, setText] = useState(textName !== undefined ? textName : "");
  const [columns, setColumns] = useState<number>(0);
  const [rows, setRows] = useState<number>(0);
  const keywordData: any[] = ["Given", "And", "Then"];
  const menuData: any[] = menuList;

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const handleOk = () => {
    const data = {
      keyword,
      text,
      isChecked,
      columns,
      rows,
    };

    onDataSubmit(data);
    setOpen(false);
    onClose();
  };
  const handleColumns = (e: any) => {
    if (e.target.value === "") setColumns(0);
    else setColumns(e.target.value);
  };
  const handleRows = (e: any) => {
    if (e.target.value === "") setRows(0);
    else setRows(e.target.value);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
    >
      <Box className={popupstyles.popupDropDownBox}>
        <FormControl>
          <InputLabel id="keyword-label">Keyword</InputLabel>
          <Select
            fullWidth
            label="Keyword"
            labelId="keyword-label"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          >
            {keywordData.map((keywords, keywordDataIndex) => (
              <MenuItem
                key={keywordDataIndex}
                value={keywords}
              >
                {keywords}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="text-label">Text</InputLabel>
          <Select
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 40 * 10,
                  width: 220,
                },
              },
            }}
            labelId="text-label"
            label="Text"
            fullWidth
            onChange={(e) => setText(e.target.value)}
            value={text}
          >
            {menuData.map((listData, listIndex) => (
              <MenuItem
                key={listIndex}
                value={listData}
              >
                {stepItem(listData)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={popupstyles.checkboxOuterBox}>
        <Checkbox
          className={popupstyles.checkBoxAndTextCss}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <Typography className={popupstyles.checkBoxAndTextCss}>
          Attach data-table
        </Typography>
      </Box>
      <Box className={popupstyles.popupTextFieldBox}>
        <TextField
          className={popupstyles.popupColumnTextFieldCss}
          id="outlined-number"
          label="Enter no.of columns"
          type="number"
          onChange={(e) => {
            handleColumns(e);
          }}
          disabled={!isChecked}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 1 },
          }}
        />
        <TextField
          className={popupstyles.popupRowTextFieldCss}
          id="outlined-number"
          label="Enter no.of rows"
          type="number"
          onChange={(e) => {
            handleRows(e);
          }}
          disabled={!isChecked}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: 1 },
          }}
        />
      </Box>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={handleOk}
          disabled={
            keyword === "" ||
            text === "" ||
            (isChecked && (rows === 0 || columns === 0))
          }
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
