import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { runautomation } from "src/clients/RunScenarioService";
import { schedule, update } from "src/clients/ScheduleJobService";
import { configformdata } from "src/types/Configuretypes";
import { Feature } from "src/types/Feature";
import { jobTypes, responseStatus } from "src/types/ScheduleJobStatus";
import { formatDateStr } from "src/utils/FormatDateStr";
import { checkinprogressrecords } from "../../clients/CheckInprogressService";
import { selectUser } from "../../redux/slices/userSlice";
import { getOrgs } from "../../types/Organizations";
import { convertJSONToGherkin } from "../../utils/FileUtil";
import { useInterval } from "../../utils/useInterval";
import runFormStylings from "../Configure/RunForm.module.css";
import WarningPopup from "../modal/WarningPopup";
import ScheduleForm from "./ScheduleForm";

interface RunScenarioScreenProps {
  featureFile?: Feature;
  configTestLoadingOnBack?: (booleanValue: boolean) => void;
  editformdata?: configformdata | null;
}

const RunForm = ({
  featureFile,
  configTestLoadingOnBack,
  editformdata,
}: RunScenarioScreenProps) => {
  const location = useLocation();
  const state = location.state as RunScenarioScreenProps | null;
  const user = useSelector(selectUser);
  editformdata = state?.editformdata;
  const configFormData: configformdata = editformdata ?? {
    tags: "",
    countrycode: getOrgs(user.productCode)[0],
    emails: [],
    testDataFiles: null,
    featureFiles: null,
    jobDescription: "",
    username: user.userId,
    isScheduledJob: false,
    jobStartDate: null,
    jobExecutionTime: null,
    recurrenceDaysOfWeek: [],
    jobEndDate: null,
    jobFrequency: null,
    jobType: null,
    scheduleJobId: null,
  };
  const [configForm, setConfigForm] = useState<configformdata>(configFormData);
  const [email, setEmail] = useState<string>("");
  const [dockerstatus, setDockerStatus] = useState<boolean>(false);
  const [schedulePopUpFlag, setSchedulePopUpFlag] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const TestDatafilesList = configForm.testDataFiles
    ? [...configForm.testDataFiles]
    : [];
  const featurefilesList = configForm.featureFiles
    ? [...configForm.featureFiles]
    : [];
  const [loading, setLoading] = useState<boolean>(false);
  const formData = new FormData();
  const navigate = useNavigate();
  const [isValidTag, setIsValidTag] = useState<boolean>(true);
  const checkInprogressRecords = async () => {
    try {
      setLoading(true);
      const InprogressRecordsResponse = await checkinprogressrecords(
        user.auth,
        user.userId
      );
      if (InprogressRecordsResponse.ok) {
        const data = await InprogressRecordsResponse.json();
        setDockerStatus(data.dockerStatus);
      } else {
        const error = await InprogressRecordsResponse.json();
        toast.error("Error: " + error.error);
      }
    } catch (e) {
      console.log("Inpogress records", e);
    } finally {
      setLoading(false);
    }
  };

  const handleFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    setConfigForm({ ...configForm, testDataFiles: selectedFiles });
  };
  const handlefeatureFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    setConfigForm({ ...configForm, featureFiles: selectedFiles });
  };

  const runJob = async (e: FormEvent) => {
    e.preventDefault();
    if (configForm.emails.length === 0) {
      setIsValidTag(false);
      setError("Please enter at least one email.");
      return;
    }
    setLoading(true);
    setDockerStatus(false);
    formData.append("email", configForm.emails.join(","));
    formData.append("countryCode", configForm.countrycode);
    formData.append("jobDescription", configForm.jobDescription);
    formData.append("username", configForm.username);
    formData.append("tags", configForm.tags);
    TestDatafilesList.forEach((file, index) => {
      formData.append("testDataFiles", file);
    });
    if (featureFile) {
      const featureString = convertJSONToGherkin(featureFile);
      const featureFileBlob = new Blob([featureString], { type: "text/plain" });
      const featureBinaryFile = new File(
        [featureFileBlob],
        featureFile.name + ".feature"
      );
      formData.append("featureFiles", featureBinaryFile);
    } else {
      featurefilesList.forEach((file, index) => {
        formData.append("featureFiles", file);
      });
    }
    if (configForm.isScheduledJob) {
      formData.append(
        "isScheduledJob",
        configForm.isScheduledJob ? "true" : "false"
      );
      formData.append(
        "jobStartDate",
        configForm.isScheduledJob
          ? configForm.jobStartDate
            ? formatDateStr(
                new Date(
                  dayjs(configForm.jobStartDate).format("YYYY-MM-DD") +
                    " " +
                    dayjs(configForm.jobExecutionTime).format("HH:mm")
                ),
                "YYYY-MM-DD HH:mm:ss",
                false
              )
            : ""
          : ""
      );
      formData.append(
        "jobEndDate",
        configForm.isScheduledJob
          ? configForm.jobEndDate && configForm.jobStartDate
            ? dayjs(configForm?.jobEndDate) > dayjs(configForm?.jobStartDate)
              ? formatDateStr(
                  new Date(configForm.jobEndDate),
                  "YYYY-MM-DD HH:mm:ss",
                  false
                )
              : formatDateStr(
                  new Date(configForm.jobEndDate + " 23:59"),
                  "YYYY-MM-DD HH:mm:ss",
                  false
                )
            : ""
          : ""
      );
      formData.append(
        "recurrenceDaysOfWeek",
        configForm.isScheduledJob
          ? configForm.recurrenceDaysOfWeek.join(",")
          : ""
      );
      formData.append("jobType", configForm.jobType ? configForm.jobType : "");
      formData.append(
        "jobFrequency",
        configForm.jobType === jobTypes[0]
          ? dayjs(configForm.jobFrequency).format("HH:mm")
          : dayjs(configForm.jobExecutionTime).format("HH:mm")
      );
      configForm.scheduleJobId &&
        formData.append("scheduleJobId", configForm.scheduleJobId.toString());
    }
    try {
      const response = formData.get("isScheduledJob")
        ? formData.get("scheduleJobId")
          ? await update(user.auth, formData, user.productCode)
          : await schedule(user.auth, formData, user.productCode)
        : await runautomation(user.auth, formData, user.productCode);
      const runresponse = await response.json();
      if (response.ok) {
        if (
          runresponse.containerId !== responseStatus[0] &&
          runresponse.containerId !== responseStatus[1] &&
          runresponse.containerId !== responseStatus[2]
        ) {
          toast.success("TestSuite Configured successfully");
          navigate("/reports");
        } else if (runresponse.containerId === responseStatus[0]) {
          toast.error("TestSuite Configuration Failed");
          setDockerStatus(false);
        } else if (runresponse.containerId === responseStatus[1]) {
          toast.success("Job Scheduled successfully");
          navigate("/schedule");
        } else if (runresponse.containerId === responseStatus[2]) {
          toast.success(
            `Job ${configForm.scheduleJobId} ${responseStatus[2]} successfully`
          );
          navigate("/schedule");
        }
      } else {
        toast.error("TestSuite Configuration Failed");
        setDockerStatus(false);
      }
    } catch (error) {
      toast.error("TestSuite Configuration Failed");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsValidTag(true);
    setError("");
    setEmail(e.target.value);
  };

  const removeEmailChip = (index: number) => {
    setConfigForm({
      ...configForm,
      emails: configForm.emails.filter((_, i) => i !== index),
    });
    setEmail("");
  };

  const handleKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (
      evt.key === "Enter" &&
      email.trim() !== "" &&
      email.length > 0 &&
      email.trim() !== "/n"
    ) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValidTag(emailPattern.test(email));
      if (!emailPattern.test(email)) {
        setError("Invalid email found");
      } else {
        if (configForm.emails.includes(email)) {
          setError("Email already present");
          return;
        }
        setConfigForm({
          ...configForm,
          emails: [...configForm.emails, email],
        });
        setError("");
      }
    } else if (evt.key === "Enter" && configForm.emails.length === 0) {
      setIsValidTag(false);
      setError("Please enter at least one email.");
    }
    setEmail("");
  };

  useEffect(() => {
    checkInprogressRecords();
  }, []);

  useEffect(() => {}, [configForm]);

  useInterval(
    () => {
      if (!dockerstatus) {
        checkInprogressRecords();
      }
    },
    parseInt(process.env.REFRESH_INTERVAL ?? "60000", 10)
  );
  if (loading) {
    return (
      <Box
        className={
          featureFile
            ? runFormStylings.loaderBoxCss2
            : runFormStylings.loaderBoxCss1
        }
      >
        <CircularProgress disableShrink />
      </Box>
    );
  }
  console.log(configForm);
  return (
    <>
      <form
        onSubmit={runJob}
        encType="multipart/form-data"
        style={{
          minWidth: "max-content",
        }}
      >
        <Box className={runFormStylings.outterBoxCss}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontSize={"18px"}
              fontWeight={"medium"}
              alignItems={"center"}
              width={"40%"}
              justifyContent={"left"}
              text-align={"right"}
              sx={{ float: "left" }}
              flexGrow={1}
            >
              Email*:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  aliginContent: "center",
                  justifyContent: "center",
                  flexGrow: 1,
                  border: "1px solid #ADADAD",
                  borderRadius: "4px",
                  // height: "40px",
                }}
              >
                {/* <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={emails}
                  size="small"
                  sx={{
                    flexWrap: "nowrap",
                    " & .MuiAutocomplete-inputRoot": {
                      flexWrap: "nowrap",
                    },
                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                      paddingBottom: "0px",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    },
                    "& .MuiOutlinedInput-root": {
                      minWidth: "100%",
                      width: "max-content",
                      borderColor: "white",
                      padding: "0px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        border: "none",
                      },

                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },

                        "&:after": {
                          border: "none",
                        },
                      },
                    },
                  }}
                  disableClearable
                  renderTags={(value) => (
                    <div
                      style={{
                        display: "flex",
                        gap: 0.5,
                        border: "none",
                      }}
                    >
                      {value.map((stringEmail, stringEmailIndex) => (
                        <Chip
                          key={stringEmailIndex}
                          label={stringEmail}
                          sx={{}}
                          onDelete={() => removeEmailChip(stringEmailIndex)}
                        />
                      ))}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="email"
                      size="small"
                      sx={{
                        flexWrap: "nowrap",
                        maxWidth: "470px",
                        overflowX: "auto",
                        borderRadius: "4px",
                        padding: "5px",
                        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                          width: "max-content",
                        },
                      }}
                      // multiline
                      // maxRows={2}
                      placeholder="Enter your Email Id"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleKeyDown(e)
                      }
                      onChange={handleEmailChange}
                      error={!isValidTag}
                    />
                  )}
                /> */}

                {/* vertical scroll */}

                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={configForm.emails}
                  size="small"
                  sx={{
                    flexWrap: "wrap",
                    " & .MuiAutocomplete-inputRoot": {
                      flexWrap: "wrap",
                    },
                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                      paddingBottom: "0px",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "max-content",
                      borderColor: "white",
                      padding: "0px",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        border: "none",
                      },

                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },

                        "&:after": {
                          border: "none",
                        },
                      },
                    },
                  }}
                  disableClearable
                  renderTags={(value) => (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        columnGap: "0.5rem",
                        rowGap: "0.3rem",
                        border: "none",
                      }}
                    >
                      {value.map((stringEmail, stringEmailIndex) => (
                        <Chip
                          key={stringEmailIndex}
                          label={stringEmail}
                          sx={{}}
                          onDelete={() => removeEmailChip(stringEmailIndex)}
                        />
                      ))}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="email"
                      size="small"
                      sx={{
                        flexWrap: "wrap",
                        maxHeight: "80px",
                        minHeight: "fit-content",
                        overflowY: "auto",
                        borderRadius: "4px",
                        padding: "5px",
                        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                          height: "max-content",
                          width: "max-content",
                          marginTop: "3px",
                          marginBottom: "2px",
                        },
                      }}
                      // multiline
                      // maxRows={2}
                      placeholder="Enter your Email Id"
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        handleKeyDown(e)
                      }
                      onChange={handleEmailChange}
                      error={!isValidTag}
                    />
                  )}
                />
              </Box>
              {error && <FormHelperText error>{error}</FormHelperText>}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontSize={"18px"}
              fontWeight={"medium"}
              alignItems={"center"}
              width={"40%"}
              justifyContent={"left"}
              text-align={"right"}
              sx={{ float: "left" }}
            >
              Tags*:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                aliginContent: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                size="small"
                required
                id="Tags"
                value={configForm.tags}
                placeholder="Job Scenario Tags"
                onBlur={(e: any) => {
                  const newValue = e.target.value.replace(
                    /\b(?<!@)\w+\b(?!@)/g,
                    (match: string) => match.toLowerCase()
                  );
                  setConfigForm({ ...configForm, tags: newValue });
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setConfigForm({ ...configForm, tags: e.target.value });
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontSize={"18px"}
              fontWeight={"medium"}
              alignItems={"center"}
              width={"40%"}
              justifyContent={"left"}
              text-align={"right"}
              sx={{ float: "left" }}
            >
              Job Description:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                aliginContent: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                size="small"
                id="jobdescription"
                InputProps={{
                  inputProps: {
                    maxLength: 60,
                  },
                }}
                value={configForm.jobDescription}
                placeholder="Enter your Job Details"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfigForm({
                    ...configForm,
                    jobDescription: e.target.value,
                  })
                }
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontSize={"18px"}
              fontWeight={"medium"}
              alignItems={"center"}
              width={"40%"}
              justifyContent={"left"}
              text-align={"right"}
              sx={{ float: "left" }}
            >
              Organization*:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                aliginContent: "center",
                justifyContent: "center",
              }}
            >
              <FormControl>
                <Select
                  size="small"
                  required
                  id="orgcode"
                  value={configForm.countrycode}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: "center",
                    "& .MuiSelect-root": {
                      alignSelf: "flex-start",
                    },
                  }}
                  onChange={(e) =>
                    setConfigForm({
                      ...configForm,
                      countrycode: e.target.value,
                    })
                  }
                >
                  {getOrgs(user.productCode).map((org: string) => (
                    <MenuItem
                      key={org}
                      value={org}
                    >
                      {org}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h6"
              fontSize={"18px"}
              fontWeight={"medium"}
              alignItems={"center"}
              width={"40%"}
              justifyContent={"left"}
              text-align={"right"}
              sx={{ float: "left" }}
            >
              Data Files:
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                aliginContent: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  border: "1px solid #fe948f",
                }}
              >
                <CloudUploadIcon
                  color="secondary"
                  sx={{
                    color: "#fe948f",
                    aliginItems: "center",
                    fontSize: 30,
                    ml: "20%",
                  }}
                />
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"flex-start"}
                  ml={"2%"}
                >
                  Upload Test Data files
                </Box>
                <input
                  type="file"
                  name="files"
                  id="testfiles"
                  accept=".json"
                  multiple
                  onChange={handleFiles}
                  style={{
                    display: "block",
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
              </Button>
            </Box>
          </Box>
          {!featureFile && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
                flexGrow: 1,
              }}
            >
              <Typography
                variant="h6"
                fontSize={"18px"}
                fontWeight={"medium"}
                alignItems={"center"}
                width={"40%"}
                justifyContent={"left"}
                text-align={"right"}
                sx={{ float: "left" }}
              >
                Test Scripts:
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  aliginContent: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    border: "1px solid #fe948f",
                  }}
                >
                  <CloudUploadIcon
                    color="secondary"
                    sx={{ color: "#fe948f", fontSize: 30, ml: "20%" }}
                  />
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    ml={"2%"}
                  >
                    Upload Test Scripts
                  </Box>
                  <input
                    type="file"
                    name="featurefiles"
                    id="featurefiles"
                    accept=".feature"
                    multiple
                    onChange={handlefeatureFiles}
                    style={{
                      display: "block",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexGrow: 1,
              gap: "10px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              disabled={
                configForm.emails.length === 0 || configForm.tags.length === 0
              }
              onClick={() => {
                setSchedulePopUpFlag(true);
                setConfigForm({ ...configForm, isScheduledJob: true });
              }}
            >
              Schedule Job
            </Button>
            <Button
              size="small"
              type="submit"
              variant="outlined"
              disabled={
                configForm.emails.length === 0 ||
                configForm.tags.length === 0 ||
                !dockerstatus
              }
            >
              EXECUTE
            </Button>
          </Box>
        </Box>
      </form>
      {schedulePopUpFlag && (
        <WarningPopup
          header={
            configForm.scheduleJobId ? "Edit Schedule Job" : "Schedule Job"
          }
          isOpen={true}
          onClose={() => {
            setSchedulePopUpFlag(false);
            setConfigForm({
              ...configForm,
              isScheduledJob: false,
              scheduleJobId: null,
            });
          }}
          actionButtons={[
            {
              name: configForm.scheduleJobId ? "Save" : "Schedule",
              onButtonClick: runJob,
              isDisabled:
                dayjs(configForm.jobStartDate).toString() === "Invalid Date" ||
                dayjs(configForm.jobEndDate).toString() === "Invalid Date" ||
                dayjs(configForm.jobFrequency).toString() === "Invalid Date" ||
                dayjs(configForm.jobExecutionTime).toString() ===
                  "Invalid Date" ||
                dayjs(configForm.jobStartDate).format("YYYY-MM-DD") >
                  dayjs(configForm.jobEndDate).format("YYYY-MM-DD") ||
                dayjs(configForm.jobFrequency).format("HH:mm").toString() ===
                  "00:00" ||
                dayjs(configForm.jobEndDate).isBefore(
                  dayjs().format("YYYY-MM-DD")
                ) ||
                configForm.recurrenceDaysOfWeek.length === 0,
            },
            {
              name: "Cancel",
              onButtonClick: () => {
                setSchedulePopUpFlag(false);
                const baseConfig = {
                  isScheduledJob: false,
                  scheduleJobId: null,
                };

                setConfigForm({
                  ...configForm,
                  ...baseConfig,
                });
              },
            },
          ]}
        >
          <ScheduleForm
            configForm={configForm}
            setConfigForm={setConfigForm}
          />
        </WarningPopup>
      )}
    </>
  );
};
export default RunForm;
