import WarningIcon from "@mui/icons-material/Warning";
import { Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import lodash from "lodash";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { saveClient } from "src/clients/ClientService";
import { selectUser } from "src/redux/slices/userSlice";
import {
  AppCredentials,
  ClientData,
  Products,
  UserData,
} from "src/types/UserData";
import { ClientSetUpContext } from "../context/Context";
import WarningPopup from "../modal/WarningPopup";
import CreateClient from "./CreateClient";
import ListOfUsers from "./ListOfUsers";
import tabComponentStyles from "./TabComponent.module.css";

interface tabComponentProps {
  mode: string;
  selectedClient: ClientData;
  productsList: Products[];
  handleOnCreateClientCancel: () => void;
}
const TabComponent = ({
  mode,
  selectedClient,
  productsList,
  handleOnCreateClientCancel,
}: tabComponentProps) => {
  const [selectedClientData, setSelectedClientData] = useState(selectedClient);
  const copyUserDataList = (userDataList: UserData[]): UserData[] =>
    userDataList.map((user) => ({
      ...user,
      userAppCredentials: user.userAppCredentials.map((userAppCredential) => ({
        ...userAppCredential,
      })),
    }));

  const copyAppCredentialsList = (
    appCredentialsList: AppCredentials[]
  ): AppCredentials[] =>
    appCredentialsList.map((appCredentials) => ({
      ...appCredentials,
      products: { ...appCredentials.products },
    }));

  const copyClientData = (clientData: ClientData): ClientData => ({
    ...clientData,
    userDataList: copyUserDataList(clientData.userDataList),
    appCredentialsList: copyAppCredentialsList(clientData.appCredentialsList),
  });
  const [clientData, setClientData] = useState<ClientData>(
    copyClientData(selectedClient)
  );
  const [isDiscardPopUpOpen, setIsDiscardPopUpOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState("1");
  const user = useSelector(selectUser);

  const compareUserLists = (
    initialList: UserData[],
    updatedList: UserData[]
  ) => {
    if (initialList.length !== updatedList.length) {
      return true;
    }

    for (let i = 0; i < initialList.length; i++) {
      if (!lodash.isEqual(initialList[i], updatedList[i])) {
        return true;
      }
    }

    return false;
  };

  const handleOnTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (
      compareUserLists(selectedClientData.userDataList, clientData.userDataList)
    ) {
      setIsDiscardPopUpOpen(true);
    } else {
      setTabValue(newValue);
    }
  };

  const handleOnPopUpSave = (): Promise<ClientData> => {
    return new Promise((resolve, reject) => {
      saveClient(clientData, user.userId, user.password).then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          setClientData(data);
          setSelectedClientData(data);
          resolve(data);
          toast.success("Saved Successfully");
        } else {
          toast.error("Failed to save");
        }
      });
      setIsDiscardPopUpOpen(false);
    });
  };

  const handleOnPopUpClose = () => {
    setIsDiscardPopUpOpen(false);
  };

  const handleOnPopUpDiscard = () => {
    setIsDiscardPopUpOpen(false);
    setClientData((prev) => {
      const updatedClientData = { ...prev };
      const updatedUserdataList = [...selectedClientData.userDataList];
      updatedClientData.userDataList = updatedUserdataList;
      return updatedClientData;
    });
    setTabValue("1");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ClientSetUpContext.Provider value={{ clientData, setClientData }}>
        <Tabs
          value={tabValue}
          onChange={handleOnTabChange}
          centered
          // sx={{ display: "none" }}
        >
          <Tab
            value="1"
            label={mode === "Edit" ? "Edit Client" : "Create Client"}
          />
          <Tab
            value="2"
            label="Client Users"
            disabled={
              !user?.permissionsList.includes("VIEW USER") ||
              !clientData?.clientId
            }
          />
        </Tabs>
        {tabValue === "1" && (
          <CreateClient
            mode={mode}
            initialData={selectedClientData}
            productsList={productsList}
            handleOnPopUpSave={handleOnPopUpSave}
            handleOnCreateClientCancel={handleOnCreateClientCancel}
          />
        )}
        {tabValue === "2" && (
          <ListOfUsers
            clientCode={selectedClientData.clientCode}
            handleOnPopUpSave={handleOnPopUpSave}
          />
        )}
        {isDiscardPopUpOpen && (
          <WarningPopup
            isOpen={true}
            onClose={handleOnPopUpClose}
            actionButtons={[
              {
                name: "Save",
                onButtonClick: handleOnPopUpSave,
              },
              {
                name: "Discard",
                onButtonClick: handleOnPopUpDiscard,
              },
            ]}
          >
            <Box className={tabComponentStyles.warningPopUpBox}>
              <WarningIcon />
              <Typography variant="body2">
                There are changes in the data do you want to save it.
              </Typography>
            </Box>
          </WarningPopup>
        )}
      </ClientSetUpContext.Provider>
    </Box>
  );
};

export default TabComponent;
